import {formOptionsPreset} from "@/shared/fields";
export const formOptionsGen = ($this) => {
  return [
    [
      {
        name: '사용여부', key: 'use_yn', options: [
          {text: '전체', value: 'ALL'},
          {text: '사용', value: 'y', variant: 'success'},
          {text: '미사용', value: 'n', variant: 'warning'}
        ]
      },
      {
        name: '유형', key: 'shop_type', role: 'SHOP_R', options: [
          {text: '전체', value: 'ALL'},
          {text: '부티크', value: 'boutique'},
          {text: '편집샵', value: 'edit_shop'},
          {text: '병행', value: 'parallel'},
          {text: '자사', value: 'balaan'},
          {text: '스토어', value: 'store'},
          {text: '기타', value: 'etc'},
        ]
      },
      {
        name: '통화', key: 'currency', options: [
          {text: '전체', value: 'ALL'},
          {text: 'KRW', value: 'KRW'},
          {text: 'EUR', value: 'EUR'},
          {text: 'USD', value: 'USD'},
          {text: 'GBP', value: 'GBP'},
          {text: 'CAD', value: 'CAD'},
          {text: 'HKD', value: 'HKD'},
          {text: 'AUD', value: 'AUD'},
        ]
      },
      {
        name: '배송타입', key: 'delivery_type', options: [
          {text: '전체', value: 'ALL'},
          {text: '해외', value: 'abroad', variant: 'success'},
          {text: '국내', value: 'domestic', variant: 'info'},
          {text: 'BOTH', value: 'both'},
        ]
      },
      {
        name: '직배송여부', key: 'logistics', options: [
          {text: '전체', value: 'ALL'},
          {text: 'BALAAN 물류', value: 'balaan'},
          {text: 'Direct 배송', value: 'direct'},
        ]
      },
      {name: '반품가능여부', key: 'return_ok', options: formOptionsPreset.AVAIL_YN},
      {name: '직접반품가능', key: 'direct_return_yn', options: formOptionsPreset.AVAIL_YN},
      {
        name: '반품배송비 설정 여부', key: 'return_cost_type', options: [
          {text: '전체', value: 'ALL'},
          {text: 'SHOP 단위', value: 'shop'},
          {text: '상품별', value: 'goods'}
        ]
      },
      {name: '교환배송비 설정 여부', key: 'exchange_cost_type', options: formOptionsPreset.YESNO_YN},
      // {key: 'divider'},
      {
        name: '파트너 이름 노출', key: 'name_open', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: true, variant: 'success'},
          {text: '미노출', value: false, variant: 'warning'}
        ]
      },
      {
        name: '상품링크 노출', key: 'expose_goods_link', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: 'y', variant: 'success'},
          {text: '미노출', value: 'n', variant: 'warning'}
        ]
      },
      {
        name: '최초 등록시 노출', key: 'init_display_status', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: 'view', variant: 'success'},
          {text: '미노출', value: 'notview', variant: 'warning'}
        ]
      },
      {
        name: '상품명 보정방식', key: 'goods_name_policy', options: [
          {text: '전체', value: 'ALL'},
          {text: '원본상품명으로 교체', value: 'change'},
          {text: '없음', value: 'none'}
        ]
      },
      {name: '오늘출발 EP 전송 여부', key: 'ep_fast_delivery', options: formOptionsPreset.YESNO_YN},
      {name: '긴 이미지 대응', key: 'long_image', role: 'SHOP_R', options: formOptionsPreset.EXISTS_YN},
      {
        name: 'TestShop 여부', key: 'is_test_shop', options: [
          {text: '전체', value: 'ALL'},
          {text: 'N', value: false, variant: 'warning'},
          {text: 'Y', value: true, variant: 'success'}
        ]
      },
      {
        name: 'B 최저가 계산에서 제외', key: 'bPriceExclude', options: [
          {text: '전체', value: 'ALL'},
          {text: '제외', value: true, variant: 'warning'},
        ]
      },
      {
        name: '카드 프로모션에 포함', key: 'cardPromotionEnabled', options: [
          {text: '전체', value: 'ALL'},
          {text: '포함', value: true, variant: 'success'},
        ]
      },
      {
        name: '파트너 등급', key: 'partnerGrade', options: [
          {text: '전체', value: 'ALL'},
          {text: 'PREMIUM', value: 'premium', variant: 'success'},
          {text: 'SMART', value: 'smart', variant: 'success'},
          {text: 'NORMAL', value: 'normal', variant: 'info'},
          {text: 'STARTER 1', value: 'starter1', variant: 'info'},
          {text: 'STARTER 2', value: 'starter2', variant: 'info'},
          {text: '없음', value: 'none', variant: 'secondary'},
        ]
      },
      {name: 'DynamicPricing', key: 'dynamicPricingEnabled', role: 'SHOP_R', options: [
          {text: '전체', value: 'ALL'},
          {text: 'Y', value: true, variant: 'success'},
        ]
      },
      {key: 'divider'},
      {
        name: '가격설정대상', type: 'checkbox', key: 'pt_field', role: 'SHOP_R', options: [
          {text: 'Consumer', value: 'consumer'},
          {text: 'Newin', value: 'newin'},
          {text: 'Price', value: 'sale'},
          {text: 'Supply', value: 'supply'}
        ]
      },
      {name: '현지가격설정', key: 'pt_price', role: 'SHOP_R', options: formOptionsPreset.EXISTS_YN},
      {name: '해외배송비', key: 'pt_delivery', role: 'SHOP_R', options: formOptionsPreset.YESNO_YN},
      {name: '국내가격설정', key: 'pt_krw_price', role: 'SHOP_R', options: formOptionsPreset.EXISTS_YN},
      {name: '국내배송비', key: 'pt_krw_delivery', role: 'SHOP_R', options: formOptionsPreset.YESNO_YN},
      {name: 'PG수수료', key: 'pt_pg', role: 'SHOP_R', options: formOptionsPreset.YESNO_YN},
      {name: '중고수수료', key: 'rebate_rate_used', role: 'SHOP_R', options: formOptionsPreset.YESNO_Y},
      // {
      //   name: '정산 기준', key: 'base_expected_settlement_date', role: 'SHOP_R', options: [
      //     {text: '전체', value: 'ALL'},
      //     {text: '구매확정', value: 'confirmation', variant: 'primary'},
      //     {text: '배송완료', value: 'del_complete', variant: 'success'},
      //   ]
      // },
      {
        name: '가격고정 제외 여부', key: 'is_exclude_priceLimit', options: [
          {text: '전체', value: 'ALL'},
          {text: 'N', value: false, variant: 'warning'},
          {text: 'Y', value: true, variant: 'success'},
        ]
      },
      {
        name: '주문제작 판매 가능 여부', key: 'orderMade', options: [
          {text: '전체', value: 'ALL'},
          {text: 'Y', value: true, variant: 'success'},
          {text: 'N', value: false, variant: 'warning'},
        ]
      },
      {
        name: '위탁상품 판매 가능 여부', key: 'consignUsed', options: [
          {text: '전체', value: 'ALL'},
          {text: 'Y', value: true, variant: 'success'},
          {text: 'N', value: false, variant: 'warning'},
        ]
      },
      {key: 'divider'},
      {
        name: '재고연동', key: 'stock_manage', role: 'SHOP_R', options: [
          {text: '전체', value: 'ALL'},
          {text: 'FEED', value: 'auto', variant: 'success'},
          {text: '수동', value: 'manual', variant: 'primary'}
        ]
      },
      {
        name: '연동원천', key: 'sync_origin', options: [
          {text: '전체', value: 'ALL'},
          {text: '자동(Feed)', value: 'feed'},
          {text: '파트너센터', value: 'partner'},
          {text: '위즈파스타', value: 'partner_wizfasta'},
          {text: 'EC모니터', value: 'partner_ecm'},
          {text: '사방넷', value: 'partner_sabangnet'},
          {text: '스토어봇', value: 'partner_storebot'},
          {text: '더망고', value: 'partner_themango'},
          {text: '샵플링', value: 'partner_shopling'},
          {text: '이지어드민', value: 'partner_ezadmin'},
          {text: '세원셀릭', value: 'partner_sewonsellic'},
          {text: '샵링커', value: 'partner_shoplinker'},
          {text: '파트너API', value: 'partner_api'},
          {text: '수동(Mapped)', value: 'mapped'},
          {text: '미정', value: 'unknown'},
        ]
      },
      {
        name: '연동유형', key: 'fetch_type', options: [
          {text: '전체', value: 'ALL'},
          ...$this.$C.SHOP_FETCH_TYPE.map(e => ({text: e.name, value: e.code}))
        ]
      },
      {key: 'divider'},
      {
        name: '정렬기준', key: 'sortKey', options: [
          {text: 'Shop ID', value: 'shop_id', variant: 'success'},
          {text: 'Name', value: 'boutique'},
          {text: '평균배송일', value: 'delivery_day'},
          {text: '평균배송일(BOTH:해외)', value: 'delivery_day_abroad'},
          {text: '발송약정일', value: 'shipment_contract_date'},
          {text: '반품배송비', value: 'return_cost'},
          {text: '반품배송비(BOTH:해외)', value: 'return_cost_abroad'},
          {text: '수수료', value: 'rebate_rate'},
          {text: '계약일', value: 'contract_date'},
          {text: '입점일', value: 'display_date'},
          {text: '최근수정시각', value: '_mdt'},
        ]
      },
      {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
      {
        name: '판매 가능 카테고리', type: 'checkbox', key: 'categoryWhiteList', role: 'SHOP_R', options: [
          {text: 'WOMEN', value: '009'},
          {text: 'MEN', value: '010'},
          {text: '골프', value: '011'},
          {text: '키즈', value: '020'},
          {text: '리빙', value: '030'},
          {text: '테크', value: '050'},
          {text: '스포츠', value: '040'},
          {text: '펫', value: '060'},
          {text: '아트', value: '070'},
        ]
      },
    ]
  ];
};

export const formFieldsGen = () => {
  return [
    {group: 'Shop 속성'},
    {name: 'Shop ID', key: 'shop_id', type: 'number'},
    {name: '반품 배송비', key: 'return_cost', type: 'number', enableExists: true, width: 120},
    {name: '교환 배송비', key: 'exchange_cost', type: 'number', enableExists: true, width: 120},
    {name: '반품 배송비(해외)', key: 'return_cost_abroad', type: 'number', enableExists: true, width: 150},
    {name: '교환 배송비(해외)', key: 'exchange_cost_abroad', type: 'number', enableExists: true, width: 150},
    {name: '평균배송일', key: 'delivery_day', type: 'number', enableExists: true, width: 120},
    {name: '평균배송일(해외)', key: 'delivery_day_abroad', type: 'number', enableExists: true, width: 150},
    {name: '발송약정일', key: 'shipment_contract_date', type: 'number', enableExists: true, width: 120},
    {name: '계약일', key: 'contract_date', type: 'string', enableExists: true},
    {name: '개발완료일', key: 'developed_date', type: 'string', enableExists: true, width: 140},
    {name: '입점개시일', key: 'display_date', type: 'string', enableExists: true, width: 140},

    {group: '가격계산 기본'},
    {name: 'DP추천가 대비 갭', key: 'dynamicPricingGap', type: 'number', enableExists: true, width: 150},
    {name: 'DP 달성 1차 할인율', key: 'dpPositiveBaseRate', type: 'number', enableExists: true, width: 160},
    {name: 'DP 달성 2차 할인율', key: 'dpPositiveCondRate', type: 'number', enableExists: true, width: 160},
    {name: 'DP 달성 3차 할인율', key: 'dpPositiveBalaanRate', type: 'number', enableExists: true, width: 160},
    {name: 'DP 미달성 1차 할인율', key: 'dpNegativeBaseRate', type: 'number', enableExists: true, width: 170},
    {name: 'DP 미달성 2차 할인율', key: 'dpNegativeCondRate', type: 'number', enableExists: true, width: 170},
    {name: 'DP 미달성 3차 할인율', key: 'dpNegativeBalaanRate', type: 'number', enableExists: true, width: 170},
    {name: '해외 배송비', key: 'local_delivery_avg_cost_max', type: 'number', enableExists: true, width: 120},
    {name: '국내 배송비', key: 'domestic_delivery_cost', type: 'number', enableExists: true, width: 120},
    {name: '수수료', key: 'rebate_rate', type: 'number', enableExists: true},
    {name: '카테고리 수수료', key: 'categoryFee.rate', type: 'number', enableExists: true, width: 140},
    {name: '카테고리', key: 'categoryFee.category', type: 'string', enableExists: true, width: 130},

    {group: '가격계산 상세'},
    {name: '현지가격 할인할증 Sale', key: 'apply_sale_rate', type: 'number', enableExists: true, width: 180},
    {name: '현지가격 할인할증 Supply', key: 'apply_supply_rate', type: 'number', enableExists: true, width: 190},
    {name: '운영 할인할증 Sale', key: 'apply_sale_operate_rate', type: 'number', enableExists: true, width: 170},
    {name: '운영 할인할증 Supply', key: 'apply_supply_operate_rate', type: 'number', enableExists: true, width: 180},
    {name: '파트너 할인할증 Sale', key: 'apply_sale_partner_rate', type: 'number', enableExists: true, width: 180},
    {name: '파트너 할인할증 Supply', key: 'apply_supply_partner_rate', type: 'number', enableExists: true, width: 180},
    {name: '국내가격 할인할증 Sale', key: 'apply_sale_krw_rate', type: 'number', enableExists: true, width: 180},
    {name: '국내가격 할인할증 Supply', key: 'apply_supply_krw_rate', type: 'number', enableExists: true, width: 190},
  ];
};

export const formIncExcGen = () => {
  return [
  ];
}

export const groupFieldsGen = () => {
  return [
  ];
};
