<template>
  <div>
    <b-modal title="파트너 정보" size="xl" v-model="show" ok-title="저장" cancel-title="닫기" @hide="modalHide">
      <template v-slot:modal-title>
        <span v-if="modalMode === 'edit'">{{ item.shop_id }}. {{ item.boutique }}</span>
        <span v-else><b-badge variant="success">New</b-badge> {{ item.boutique }}</span>
      </template>
      <div class="title-sm">기본정보</div>
      <b-row>
        <b-col cols="6" lg="1">
          <small>Shop Id</small><i class="fa fa-question-circle ml-1" v-b-tooltip="'가장 마지막 Shop ID를 기준으로 자동 생성됩니다.'"></i><br/>
          <label v-if="modalMode === 'edit'" class="col-form-label">{{ item.shop_id }}</label>
          <label v-else class="col-form-label">New</label>
          <!--          <b-input v-else v-model.number="item.shop_id"></b-input>-->
        </b-col>
        <b-col cols="6" lg="3">
          <small>Shop Name</small><br/>
          <b-input v-model="item.boutique"></b-input>
        </b-col>
        <b-col>
          <small>사용중 여부</small><i class="fa fa-question-circle ml-1" v-b-tooltip="'N으로 변경 시 파트너센터, 크롤링(FEED)에서 연동이 멈추게 됩니다. 오후 11시에 등록상품이 일괄 미노출 처리됩니다.'"></i><br/>
          <label class="col-form-label">{{item.use_yn && item.use_yn.toUpperCase()}}</label>
          <b-button v-if="$R(['SHOP_W', 'SHOP_X'])" class="ml-2" size="sm" variant="light" @click="changeShopUseYN()">
            변경
          </b-button>
        </b-col>
        <b-col>
          <small>유형</small><br/>
          <b-form-select v-model="item.shop_type" :options="options.shop_type">
          </b-form-select>
        </b-col>
        <b-col>
          <small>통화</small><br/>
          <b-form-select v-model="item.currency" :options="options.currency">
          </b-form-select>
        </b-col>
        <b-col>
          <small>국가</small><br/>
          <b-form-input v-model="item.shop_country"></b-form-input>
        </b-col>
      </b-row>

      <div class="title-sm">운영정보</div>
      <b-row class="mb-2">
        <b-col cols="6" lg="3">
          <small>직배송여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.logistics" :options="options.logistics">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>계약여부(병행)</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'계약병행의 반품교환배송비는 10000원으로 설정됩니다(네이버 스마트스토어 등)'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.contract_yn" :options="options.yn"></b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>개인통관고유부호 수집여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.pccc_feed" :options="options.yn" @change="setPcccRequire"></b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>개인통관고유부호 필수여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.pccc_require" :options="options.yn"></b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>배송타입</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.delivery_type" :options="options.delivery_type">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>반품가능여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.return_ok" :options="[
                  {text: '가능', value: 'y'},
                  {text: '불가능', value: 'n'},
                ]">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3" v-if="item.return_ok === 'y'">
          <small>직접반품여부</small><i class="fa fa-question-circle ml-1" v-b-tooltip="'Y 설정 시 연락처, 반품주소 설정 필수(반품접수용), Default : N'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.direct_return_yn" :options="options.yn">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>오늘출발 딱지노출여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.today_pick_open" :options="[
                  {text: '노출', value: true},
                  {text: '미노출', value: false},
                ]">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>오늘출발 EP 전송 여부</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.ep_fast_delivery" :options="[
                  {text: 'Y', value: true},
                  {text: 'N', value: false},
                ]">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>자동 배송준비</small><br/>
          <b-form-radio-group class="col-form-label" v-model="item.autoPlaceOrder" :options="[
                  {text: 'Y', value: true},
                  {text: 'N', value: false},
                ]">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3" style="margin-left: 284px;" v-if="item.return_ok === 'y' && item.direct_return_yn === 'y'">
          <small>지정 택배사 선택</small><br/>
          <b-input-group>
            <b-input-group-prepend>
              <delivery-dropdown variant="light" @setCompany="setFixedComp"></delivery-dropdown>
            </b-input-group-prepend>
            <b-form-input type="text" v-model="item.fixed_del_company" placeholder="택배사 선택" :readonly=true></b-form-input>
          </b-input-group>
        </b-col>
<!--        <b-col cols="6" lg="3">-->
<!--          <small>정산 기준</small><br/>-->
<!--          <b-form-radio-group class="col-form-label" v-model="item.base_expected_settlement_date" :options="[-->
<!--                  {text: '구매확정', value: 'confirmation'},-->
<!--                  {text: '배송완료', value: 'del_complete'},-->
<!--                ]">-->
<!--          </b-form-radio-group>-->
<!--        </b-col>-->
      </b-row>

      <b-row class="mb-2" v-if="$R('SHOP_R')">
        <b-col md="3">
          <small>재고연동</small> <i class="fa fa-question-circle ml-1" title="수동인 경우 주문이 배송된 이후에도 주문수량에 남아있어서 재고가 살아나지 않습니다"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.stock_manage" :options="[
                {text: 'FEED', value: 'auto'},
                {text: '수동', value: 'manual'}
              ]"></b-form-radio-group>
        </b-col>
        <b-col md="3">
          <small>연동원천</small> <i class="fa fa-question-circle ml-1"  v-b-tooltip="'상품정보, 재고갱신 등의 원천을 의미합니다. 크롤링 샵일 경우 반드시 자동(FEED)로 설정 부탁드립니다.'"></i><br/>
          <b-form-select class="col-form-label" v-model="item.sync_origin" :options="options.sync_origin"></b-form-select>
        </b-col>
        <b-col md="3">
          <small>API 주문 타입</small><br/>
          <b-form-select class="col-form-label" v-model="item.api_order_type" :options="[
                  {text: '공통', value: 'common'},
                  {text: 'ATELIER', value: 'atelier'},
                  {text: 'SHOPIFY', value: 'shopify'},
                  {text: 'BRG', value: 'brg'},
                  {text: 'XMAG', value: 'xmag'},
                  {text: 'LINK2LUX', value: 'link2lux'},
                  {text: 'BEESTORE', value: 'beestore'},
                  {text: 'EFASHION', value: 'efashion'},
                  {text: 'CLOUDSTORE', value: 'cloudstore'},
                  {text: 'PRESTA', value: 'presta'},
                  {text: 'QUICK', value: 'quick'},
                  {text: '없음', value: ''},
                ]">
          </b-form-select>
        </b-col>
        <b-col cols="6" lg="2" v-if="item.shop_type === 'store'">
          <small>위탁스토어 여부</small><i class="fa fa-question-circle ml-1" v-b-tooltip="'Shop 유형이 스토어일 경우 선택, Default : N'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.dropshipping_yn" :options="options.yn"></b-form-radio-group>
        </b-col>
      </b-row>

      <b-row class="mb-2" v-if="$R('SHOP_R')">
        <b-col md="3">
          <small>계약일</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'계약이 성립된 날짜입니다(상품의 등록과 무관)'"></i><br/>
          <b-form inline>
            <date-input v-model="item.contract_date"></date-input>
          </b-form>
        </b-col>
        <b-col md="3">
          <small>개발완료일</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'개발이 완료되어 상품등록이 가능해진 일자입니다'"></i><br/>
          <b-form inline>
            <date-input v-model="item.developed_date"></date-input>
          </b-form>
        </b-col>
        <b-col md="3">
          <small>입점개시일</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'상품이 최초로 송출된 일자입니다'"></i><br/>
          <b-form inline>
            <date-input v-model="item.display_date"></date-input>
          </b-form>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="6" lg="3">
          <small>반품배송비 설정</small> <i class="fa fa-question-circle ml-1" :title="'SHOP 단위 : 판매 되는 상품의 반품배송비가 하나의 금액으로 일괄 적용 됩니다.\n'+
              '상품별 : 판매 되는 상품의 반품배송비가 하나의 금액으로 일괄 적용 되며, 상품 조회에서 상품별 수정이 가능합니다.\n'+
              '설정이 안되어 있을 경우 기본적으로 SHOP 단위로 동작합니다'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.return_cost_type" :options="options.return_cost_type">
          </b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="3">
          <small>교환배송비 설정</small> <i class="fa fa-question-circle ml-1" :title="'SHOP 단위 : 판매 되는 상품의 반품배송비가 하나의 금액으로 일괄 적용 됩니다.\n'+
              '상품별 : 판매 되는 상품의 반품배송비가 하나의 금액으로 일괄 적용 되며, 상품 조회에서 상품별 수정이 가능합니다.\n'+
              '설정이 안되어 있을 경우 기본적으로 SHOP 단위로 동작합니다'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.exchange_cost_type" :options="options.return_cost_type">
          </b-form-radio-group>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col cols="6" lg="3">
          <small>반품배송비</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'비워둘 경우 해외 30,000 ~ 100,000원, 국내 10,000 ~ 20.000원으로 책정됩니다'"></i><br/>
          <b-form inline>
            <b-form-input class="w-100px" v-model.number="item.return_cost" type="text" :readonly="item.return_cost_type === 'goods'"></b-form-input>
            원
          </b-form>
        </b-col>
        <b-col cols="6" lg="3">
          <small>교환배송비</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'비워둘 경우 해외 30,000 ~ 100,000원, 국내 10,000 ~ 20.000원으로 책정됩니다'"></i><br/>
          <b-form inline>
            <b-form-input class="w-100px" v-model.number="item.exchange_cost" type="text" :readonly="item.exchange_cost_type === 'goods'"></b-form-input>
            원
          </b-form>
        </b-col>
        <b-col cols="6" lg="3">
          <small>평균배송일</small><br/>
          <b-form inline>
            <b-form-input class="w-65px" v-model.number="item.delivery_day" type="text"></b-form-input>
            일
          </b-form>
        </b-col>
        <b-col cols="6" lg="3">
          <small>발송약정일</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'파트너 페널티와 관련된 항목으로 운영그룹에서 설정합니다. 비워두면 기본 3일로 설정됩니다.'"></i><br/>
          <b-form inline>
            <b-form-select class="w-65px" v-model.number="item.shipment_contract_date" :options="[
                  {text: 3, value: 3},
                  {text: 6, value: 6},
                  {text: 10, value: 10}
                ]" :disabled="!(['pVEkGny7EM', '4bEmm97GED', 'k1EBdZPY0y', 'jY0rp3JPE1'].includes($S.user.teamId) || $R('ADMIN'))">
            </b-form-select> &nbsp;일
            <!-- 허용: 운영그룹, BO실, 로컬B2B사업실(형준님 요청) -->
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="item.delivery_type === 'both'">
        <b-col cols="6" lg="3">
          <small>반품배송비(해외)</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'샵 생성 시 비워둘 경우 해외 30,000 ~ 100,000원, 국내 10,000 ~ 20.000원으로 책정됩니다'"></i><br/>
          <b-form inline>
            <b-form-input class="w-100px" v-model.number="item.return_cost_abroad" type="text" :readonly="item.return_cost_type === 'goods'"></b-form-input>
            원
          </b-form>
        </b-col>
        <b-col cols="6" lg="3">
          <small>교환배송비(해외)</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'샵 생성 시 비워둘 경우 해외 30,000 ~ 100,000원, 국내 10,000 ~ 20.000원으로 책정됩니다'"></i><br/>
          <b-form inline>
            <b-form-input class="w-100px" v-model.number="item.exchange_cost_abroad" type="text" :readonly="item.exchange_cost_type === 'goods'"></b-form-input>
            원
          </b-form>
        </b-col>
        <b-col cols="6" lg="3">
          <small>평균배송일(해외)</small><br/>
          <b-form inline>
            <b-form-input class="w-65px" v-model.number="item.delivery_day_abroad" type="text"></b-form-input>
            일
          </b-form>
        </b-col>
      </b-row>

      <div class="title-sm">담당자정보</div>
      <b-row class="mb-2">
        <b-col md="3">
          <small>영업담당</small><br/>
          <b-form-select class="col-form-label" v-model="item.md_id" :options="users.md" :disabled="!$R('SHOP_R')"></b-form-select>
        </b-col>
        <b-col md="3">
          <small>운영담당</small><br/>
          <b-form-select class="col-form-label" v-model="item.op_id" :options="users.op" :disabled="!$R('SHOP_R')"></b-form-select>
        </b-col>
        <b-col md="3" v-if="$R('SHOP_R')">
          <small>개발담당</small><br/>
          <b-form-select class="col-form-label" v-model="item.dev_id" :options="users.dev" :disabled="!$R('SHOP_R')"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col md="6">
          <small>카테고리별 영업담당</small><br/>
          <div class="clearfix mb-2" style="margin-top: -20px">
            <b-badge class="pull-right pointer" variant="success" @click="() => {categoryIdModalType = '영업'; modal.categoryId = true}">추가</b-badge>
          </div>
          <div v-if="!item.categoryMdId || item.categoryMdId.length === 0" class="py-2">
            카테고리별 영업담당이 없습니다.
          </div>
          <b-row v-else>
            <b-col class="py-1" cols="12" v-for="e in item.categoryMdId" :key="e.category">
              {{ (categoryMap[e.category] || {}).label || '' }} : <b>{{userMap[e.id].text}}</b>
              <b-badge class="pull-right" variant="danger"><i class="fa fa-close pointer" @click="removeCategoryMdId(e)"></i></b-badge>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <small>카테고리별 운영담당</small><br/>
          <div class="clearfix mb-2" style="margin-top: -20px">
            <b-badge class="pull-right pointer" variant="success" @click="() => {categoryIdModalType = '운영'; modal.categoryId = true}">추가</b-badge>
          </div>
          <div v-if="!item.categoryOpId || item.categoryOpId.length === 0" class="py-2">
            카테고리별 영업담당이 없습니다.
          </div>
          <b-row v-else>
            <b-col class="py-1" cols="12" v-for="e in item.categoryOpId" :key="e.category">
              {{ (categoryMap[e.category] || {}).label || '' }} : <b>{{userMap[e.id].text}}</b>
              <b-badge class="pull-right" variant="danger"><i class="fa fa-close pointer" @click="removeCategoryOpId(e)"></i></b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-modal :title="`카테고리별 ${categoryIdModalType}담당 추가`" size="lg" v-model="modal.categoryId">
        <b-alert variant="warning" show>
          카테고리별 {{categoryIdModalType}}담당은 상위 카테고리가 하위 카테고리를 포함하며 하위카테고리가 우선순위를 지닙니다.<br/>
          ex) 009002 : 담당A, 009002003 : 담당B 일 때 009002003 상품은 담당B, 009002004 상품은 담당A
        </b-alert>

        <small>카테고리</small>
        <v-select v-model="selectedCategory" :options="category"></v-select>
        <br/>
        <small>담당</small>
        <b-form-select class="col-form-label" v-model="categoryId" :options="categoryIdModalType === '영업' ? users.md : users.op" :disabled="!$R('SHOP_R')"></b-form-select>

        <template v-slot:modal-footer="{ /* ok, */cancel }">
          <b-button variant="primary" @click="addCategoryId()">
            추가
          </b-button>
          <b-button variant="secondary" @click="cancel()">
            취소
          </b-button>
        </template>
      </b-modal>

      <div class="title-sm">포함/제외 조건</div>
      <b-row class="mb-2">
        <b-col>
          <small>Test Shop</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'Test Shop 일 경우 각종 통계 및 지표에서 제외됩니다.'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.is_test_shop" :options="options.is_test_shop"></b-form-radio-group>
        </b-col>
        <b-col>
          <small>파트너 등급</small><br/>
          <b-form-select class="col-form-label" v-model="item.partnerGrade" :options="[
            {text: 'PREMIUM', value: 'premium'},
            {text: 'SMART', value: 'smart'},
            {text: '일반', value: 'normal'},
            {text: 'STARTER 1', value: 'starter1'},
            {text: 'STARTER 2', value: 'starter2'},
            {text: '없음', value: 'none'},
          ]" :disabled="!$R('SHOP_W')"></b-form-select>
        </b-col>
        <b-col>
          <small>B 최저가, 14일 판매 최저가</small><br/>
          <b-form-checkbox class="col-form-label" v-model="item.bPriceExclude">계산에서 제외
          </b-form-checkbox>
        </b-col>
        <b-col>
          <small>카드할인 프로모션 포함여부</small><br/>
          <b-form-checkbox class="col-form-label" v-model="item.cardPromotionEnabled">프로모션에 포함
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <small>가격고정 제외 Shop</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'가격고정 제외 Shop 일 경우 가격고정에서 상품 설정이 불가합니다.'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.is_exclude_priceLimit" :options="options.is_exclude_priceLimit"></b-form-radio-group>
        </b-col>
        <b-col cols="3">
          <small>주문제작 판매 가능 Shop</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'주문제작 판매 가능 Shop 이 아닌 경우 주문제작 상품 유형 설정이 불가합니다.'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.orderMade" :options="options.nyft"></b-form-radio-group>
        </b-col>
        <b-col cols="3">
          <small>위탁상품 판매 가능 Shop</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'위탁상품 판매 가능 Shop 이 아닌 경우 위탁판매상품 유형 설정이 불가합니다.'"></i><br/>
          <b-form-radio-group class="col-form-label" v-model="item.consignUsed" :options="options.nyft" disabled></b-form-radio-group>
        </b-col>
      </b-row>

      <hr/>

      <div class="text-right" v-if="$R('SHOP_W')">
        <b-button variant="outline-success" class="mr-1" @click="preset('boutique')">부티크</b-button>
        <b-button variant="outline-primary" class="mr-1" @click="preset('parallel')">계약 병행</b-button>
        <!--        <b-button variant="outline-info" class="mr-1" @click="preset('parallel_nc')">미계약 병행</b-button>-->
        <!--<b-button variant="outline-warning" @click="preset('all_include')">세금포함</b-button>-->
      </div>

      <b-tabs v-model="tabIndex">
        <b-tab title="상품관리옵션" v-if="$R('SHOP_R')">
          <div class="title-sm">상품 데이터(confirmed) 생성 룰</div>
          <b-row>
            <b-col md="2">
              <small>상품명 보정방식</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'파트너사의 상품명(mapped 기준)을 고객에게 노출할지의 여부입니다.'"></i><br/>
              <b-form-select v-model="item.goods_name_policy" :options="[
                      {text: '없음', value: 'none'},
                      {text: '원본상품명으로 교체', value: 'change'},
                    ]">
              </b-form-select>
            </b-col>
            <b-col md="2">
              <small>이미지 Trim방식
                <a href="/#/shop/imageTrimSample" target="_blank">예시</a>
              </small>
              <i class="fa fa-question-circle ml-1" v-b-tooltip="'이미지의 여백을 날린 뒤 사이즈를 조정할지의 여부입니다.'"></i>
              <br/>
              <b-form-select v-model.number="item.image_trim_type" :options="[
                      {text: '없음(0)', value: 0},
                      {text: '약하게(0.1)', value: 0.1},
                      {text: '1', value: 1},
                      {text: '2', value: 2},
                      {text: '3', value: 3},
                      {text: '4', value: 4},
                      {text: '5', value: 5},
                      {text: '6', value: 6},
                      {text: '7', value: 7},
                      {text: '8', value: 8},
                      {text: '9', value: 9},
                      {text: '10', value: 10},
                      {text: '11', value: 11},
                      {text: '12', value: 12},
                      {text: '13', value: 13},
                      {text: '14', value: 14},
                      {text: '15', value: 15},
                      {text: '16', value: 16},
                      {text: '17', value: 17},
                      {text: '18', value: 18},
                      {text: '19', value: 19},
                      {text: '20', value: 20},
                      {text: '21', value: 21},
                      {text: '22', value: 22},
                      {text: '23', value: 23},
                      {text: '24', value: 24},
                      {text: '25', value: 25},
                    ]">
              </b-form-select>
            </b-col>
            <!--            <b-col>
                          <small>상세설명이미지 제외</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'입력하지 않을 경우 비활성화됩니다'"></i><br/>
                          <b-form inline>
                            <b-checkbox v-model="chkImgOcr" disabled></b-checkbox>
                            인식되는 글자가
                            <b-input v-model="item.imageDropOcrThreshold" class="w-65px"></b-input>
                            자 이상일 때 제외
                          </b-form>
                        </b-col>-->
            <b-col>
              <small>긴 이미지를 상세설명으로</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'입력하지 않을 경우 비활성화됩니다'"></i><br/>
              <b-form inline>
                <b-checkbox v-model="chkImgRatio" disabled></b-checkbox>
                가로 : 세로가
                <b-input v-model="item.longImageToDescriptionRatio" placeholder="2.5" class="w-65px"></b-input>
                이상일 때
              </b-form>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="3">
              <small>EP 상품명 타입</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'Naver EP 에 들어가는 상품명 방식을 지정합니다'"></i><br/>
              <b-form-select v-model="item.ep_name_policy" :options="[
                  {text: 'A type', value: 'a_type'},
                  {text: 'B type', value: 'a_type'},
                  {text: 'C type', value: 'a_type'},
                  {text: 'D type', value: 'a_type'},
                  {text: '원본상품명', value: 'original'},
                ]">
              </b-form-select>
            </b-col>
          </b-row>
          <div class="title-sm">상품 노출여부</div>
          <b-row>
            <b-col cols="6" md="3">
              <small>상품링크 노출여부</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'파트너의 상품링크를 노출할지의 여부입니다.'"></i><br/>
              <b-form-radio-group class="col-form-label" v-model="item.expose_goods_link" :options="options.yn"></b-form-radio-group>
            </b-col>
            <b-col cols="6" md="3">
              <small>최초 등록시 노출여부</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'최초 등록시 미노출 상태로 등록할지에 대한 여부입니다. 값이 없더라도 기본값은 노출 입니다.'"></i><br/>
              <b-form-radio-group class="col-form-label" v-model="item.init_display_status" :options="[
                  {text: '노출', value: 'view'},
                  {text: '미노출', value: 'notview'},
                ]"></b-form-radio-group>
            </b-col>

            <b-col cols="6" md="3" v-if="item.sync_origin === 'feed'">
              <small>완전매칭 상품 생성 시 발란몰 노출 여부</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'상품등록 및 수정 페이지에서 완전 매칭 상품을 생성할 때 registered 상태로 변경에 대한 여부입니다.'"></i><br/>
              <b-form-radio-group class="col-form-label" v-model="item.changeToRegistered" :options="[
                  {text: '사용안함', value: 'n'},
                  {text: '사용함', value: 'y'},
                ]"></b-form-radio-group>
            </b-col>
          </b-row>

          <!-- 231113 현재로는 무의미하여 주석처리 -->
<!--          <div class="title-sm">송출금지대상 <small>송출을 금지할 외부몰을 선택합니다</small></div>
          <b-form-checkbox-group v-model="item.mall_policy">
            <b-form-checkbox v-for="s in targetMalls" :key="s.id" :value="s.id">{{ s.name }}</b-form-checkbox>
          </b-form-checkbox-group>-->

<!--          <div class="title-sm">EP 노출제외</div>-->
          <b-row>
            <b-col cols="6" md="3">
              <small>EP 노출제외 여부</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'EP에 노출할지의 여부입니다.'"></i><br/>
              <b-form-radio-group class="col-form-label" v-model="item.ep_exclude" :options="options.yn"></b-form-radio-group>
            </b-col>
            <b-col cols="6" md="4">
              <small>외부몰 노출 여부</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'외부몰 노출 체크를 해제할 경우, 해당 외부몰에 상품이 전송되지 않습니다.'"></i><br/>
              <b-form inline>
                <b-form-checkbox class="col-form-label mr-3" v-model="item.exportGlobal">글로벌</b-form-checkbox>
                <b-form-checkbox class="col-form-label mr-3" v-model="item.exportSS">네이버</b-form-checkbox>
                <b-form-checkbox class="col-form-label mr-3" v-model="item.exportShein">쉬인</b-form-checkbox>
                <b-form-checkbox class="col-form-label" v-model="item.exportPoison">포이즌</b-form-checkbox>
              </b-form>
            </b-col>
          </b-row>

          <div class="title-sm">카탈로그(gm) 생성 룰</div>
          <b-row>
            <b-col md="3">
              <small>상품 정보 정합성 등급</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'등급에 따라 카탈로그 생성시 사용되는 상품이 달라집니다.'"></i><br/>
              <b-form-radio-group class="col-form-label" v-model="item.goodsHealthRating" :options="options.goodsHealthRating"></b-form-radio-group>
            </b-col>
          </b-row>

          <div class="title-sm">
            판매 가능 브랜드 설정
            <i class="fa fa-exclamation-circle"
               v-b-tooltip="'해당 브랜드만 파트너센터를 통해 등록 및 판매할 수 있습니다.'"></i>
            <span class="ml-2 fs-12 text-danger">SHOP 설정 저장과 관계없이 변경 즉시 반영됩니다.</span>
          </div>
          <div class="clearfix mb-2" style="margin-top: -40px">
            <b-btn class="pull-right" size="sm" variant="success" @click="addBrandModal('brandWhitelist')">추가</b-btn>
          </div>
          <div v-if="!item.brandWhitelist || item.brandWhitelist.length === 0" class="py-2">
            모든 브랜드가 판매 가능합니다.
          </div>
          <b-row v-else>
            <b-col class="py-2" cols="12" v-for="e in item.brandWhitelist" :key="e.brand_no">
              {{ (brandMap[e.brand_no] || {}).text || '' }}
              <b-badge class="pull-right" variant="danger"><i class="fa fa-close pointer" @click="removeBrandModal('brandWhitelist', e)"></i></b-badge>
            </b-col>
          </b-row>

          <div class="title-sm">
            판매 제한 브랜드 설정
            <i class="fa fa-exclamation-circle"
               v-b-tooltip="'해당 브랜드는 파트너센터를 통해 등록 및 판매가 제한됩니다.'"></i>
            <span class="ml-2 fs-12 text-danger">SHOP 설정 저장과 관계없이 변경 즉시 반영됩니다.</span>
          </div>
          <div class="clearfix mb-2" style="margin-top: -40px">
            <b-btn class="pull-right" size="sm" variant="success" @click="addBrandModal('brandBlackList')">추가</b-btn>
          </div>
          <div v-if="!item.brandBlackList || item.brandBlackList.length === 0" class="py-2">
            모든 브랜드가 판매 가능합니다.
          </div>
          <b-row v-else>
            <b-col class="py-2" cols="12" v-for="e in item.brandBlackList" :key="e.brand_no">
              {{ (brandMap[e.brand_no] || {}).text || '' }}
              <b-badge class="pull-right" variant="danger"><i class="fa fa-close pointer" @click="removeBrandModal('brandBlackList', e)"></i></b-badge>
            </b-col>
          </b-row>

          <shop-brand-guide ref="brandListSet" v-model="modal.brandFirstSet" @set-brand-list="addBrandModal"></shop-brand-guide>
          <shop-brand-set v-model="modal.brandListSet" :type="brandListType" :item="item" @add-brand-list="addBrand"></shop-brand-set>
          <remove-shop-brand v-model="modal.removeBrand" :type="brandListType" :shopId="item.shop_id" :brand="selectedBrand" @remove-brand-list="removeBrand"></remove-shop-brand>

        </b-tab>
        <b-tab title="가격계산 기본" v-if="$R('SHOP_R')">
          <price-base ref="priceBase" v-model="item" v-bind="{priceMap, options, category, categoryMap}"></price-base>
        </b-tab>
        <b-tab title="가격계산 상세" v-if="$R('SHOP_R')">
          <price-table ref="priceTable" v-model="item" v-bind="{priceMap, exchangeMap, options}"></price-table>
        </b-tab>
        <b-tab title="파트너 정보">
          <partner v-model="item"></partner>
        </b-tab>
        <b-tab title="연동 정보">
          <shop-link v-model="item"></shop-link>
        </b-tab>
      </b-tabs>

      <hr/>

      <label>비고(내부용)</label>
      <b-textarea rows="5" v-model="item.desc"></b-textarea>
      <br>
      <label>미사용 처리 사유</label>
      <b-input-group>
        <b-input-group-prepend>
          <b-form-select :disabled="item.use_yn === 'y'" v-model="item.use_yn_reason" :options="[
              '이용 제한 조치', '고의적 부당행위 제한', '퇴점 처리 완료', '기타'
            ]" @input="() => {if (item.use_yn_reason !== '기타') item.use_yn_reason_detail = item.use_yn_reason}">
          </b-form-select>
        </b-input-group-prepend>

        <b-input :disabled="item.use_yn === 'y'" v-model="item.use_yn_reason_detail" @input="inputReason" :placeholder="item.use_yn_reason === '기타' ? '기타 사유를 입력해주세요.' : ''"></b-input>

      </b-input-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- SHOP 이 삭제되면 상품 상세가 열리지 않는 등 도처에서 문제가 발생. 비활성화 -->
        <!--        <b-button v-if="item_org.shop_id" variant="outline-danger" @click="removeShop(item_org.shop_id)">
                  SHOP 삭제
                </b-button>-->
        <b-button v-if="$R('SHOP_X') || $G('dev')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item})">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button variant="primary" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>

    <b-modal title="변경사항 확인" size="lg" v-model="modal.diff">
      <span v-html="diffHtml"></span>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="()=>{modal.diff=false;modalHide({trigger:'ok'})}">
          저장하고 닫기
        </b-button>
        <b-button size="sm" variant="danger" @click="()=>{modal.diff=false;hideModal()}">
          저장하지 않고 닫기
        </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="사용여부 변경" size="lg" v-model="modal.use_yn_reason">
      <div><b-badge variant="light">use_yn</b-badge> "{{item.use_yn}}" → "{{item.use_yn === 'y' ? 'n' : 'y'}}"</div>

      <div v-if="modal.use_yn_reason && item.use_yn === 'y'">
        <div class="border-bottom mt-3 mb-3"></div>
        <b-alert variant="danger" show>미사용 처리 시 해당 Shop의 전체 상품이 미노출 처리되며, 이용 제한 고지 알림톡이 발송됩니다.</b-alert>
        <strong class="bold">미사용 처리 사유</strong><span class="ml-1 text-danger">*</span><br/>
        <b-input-group class="mt-2">
          <b-input-group-prepend>
            <b-form-select v-model="item.use_yn_reason" :options="['이용 제한 조치', '고의적 부당행위 제한', '퇴점 처리 완료', '기타']"
                           @input="() => {if (item.use_yn_reason !== '기타') {
                             item.use_yn_reason_detail = item.use_yn_reason;
                           } else {
                             item.use_yn_reason_detail = '';
                           }}">
            </b-form-select>
          </b-input-group-prepend>
          <b-input v-model="item.use_yn_reason_detail" @input="inputReason" :placeholder="item.use_yn_reason === '기타' ? '기타 사유를 입력해주세요.' : ''"></b-input>
        </b-input-group>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="updateUseYn()">
          저장
        </b-button>
        <b-button size="sm" variant="secondary" @click="() => { cancel(); item.use_yn_reason_detail = ''; item.use_yn_reason = '';}">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="가격갱신" size="lg" v-model="modal.renewPrice">
      <div>
        가격관련 설정이 변경되었습니다. 해당 SHOP 의 상품가격 갱신을 진행하시겠습니까?
      </div>
      <div>
        (종료상품은 제외되며, 가격관련 설정이 완료되지 않았다면 완료한 뒤 상품가격 갱신을 진행해주세요)
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="renewPriceShop">
          가격갱신 진행
        </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import priceBase from '@/views/shop/ShopPriceBase.vue';
import priceTable from '@/views/shop/ShopPriceTable.vue';
import partner from '@/views/shop/ShopPartner.vue';
import shopLink from '@/views/shop/ShopLink.vue';
import dateInput from '@/views/modules/DateInput.vue';
import DeliveryDropdown from '@/views/modules/DeliveryDropdown.vue';
import shopBrandGuide from '@/views/shop/shopBrandSet/shopBrandGuide.vue';
import shopBrandSet from '@/views/shop/shopBrandSet/shopBrandSet.vue';
import removeShopBrand from '@/views/shop/shopBrandSet/removeShopBrand.vue';

export default {
  name: 'ShopModal',
  model: {prop: 'value', event: 'change'},
  props: ['priceMap', 'exchangeMap', 'modalMode', 'options', 'userMap', 'value'],
  components: {dateInput, priceBase, priceTable, partner, shopLink, DeliveryDropdown, shopBrandSet, shopBrandGuide, removeShopBrand},
  data() {
    return {
      targetMalls: this.$C.MALL.filter(e => e.use),
      item: {addr: {}},
      item_org: {addr: {}},
      tabIndex: 0,
      modal: {
        diff: false,
        categoryId: false,
        use_yn_reason: false,
        renewPrice: false,
        brandFirstSet: false,
        brandListSet: false,
        removeBrand: false,
      },
      busy: {
        addBrand: false,
        brandStat: false,
        removeBrand: false,
      },
      categoryIdModalType: '영업',
      diffHtml: '',
      users: {
        md: [],
        op: [],
        dev: [],
      },
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      categoryId: '',
      selectedCategory: null,
      brandListType: null,
      selectedBrand: null,

      priceFields: (
        'apply_consumer_customs,apply_consumer_divider,apply_consumer_krw_divider,apply_consumer_krw_rate,apply_consumer_rate,apply_consumer_roundup,' +
        'apply_consumer_partner_pre_amount,apply_consumer_partner_post_amount,' +
        'consumer_abroad_delivery,consumer_base,consumer_include_domestic_delivery,consumer_include_pg,consumer_iva_type,' +

        'apply_newin_customs,apply_newin_divider,apply_newin_krw_divider,apply_newin_krw_rate,apply_newin_rate,apply_newin_roundup,' +
        'apply_newin_partner_pre_amount,apply_newin_partner_post_amount,' +
        'newin_abroad_delivery,newin_base,newin_include_domestic_delivery,newin_include_pg,newin_iva_type,' +

        'apply_sale_customs,apply_sale_divider,apply_sale_krw_divider,apply_sale_krw_rate,apply_sale_rate,apply_sale_roundup,' +
        'apply_sale_partner_pre_amount,apply_sale_partner_post_amount,' +
        'sale_abroad_delivery,sale_base,sale_include_domestic_delivery,sale_include_pg,sale_iva_type,' +

        'apply_supply_customs,apply_supply_divider,apply_supply_krw_divider,apply_supply_krw_rate,apply_supply_rate,apply_supply_roundup,' +
        'apply_supply_partner_pre_amount,apply_supply_partner_post_amount,' +
        'supply_abroad_delivery,supply_base,supply_include_domestic_delivery,supply_include_pg,supply_iva_type,' +

        'currency,customs_included,customs_rate,customs_vat_with_delivery_yn,' +
        'domestic_delivery_cost,fixed_customs_yn,fta_yn,idv_tax_yn,iva_rate,local_delivery_avg_cost_max,local_delivery_avg_cost_per,local_delivery_cost,' +
        'local_delivery_cost_krw,local_delivery_cost_type,local_delivery_supply_cost,local_delivery_supply_cost_krw,local_delivery_supply_cost_type,' +
        'price_calculation,rebate_rate,rebate_rate_used,' +
        'tax_included_by_price,tax_included_if_price,vat_included,vat_with_idv_tax_yn,categoryFee'
      ).split(','), // TODO: blu.price 의 checkPriceMeta 의 값들과 아래의 numberItems 를 조합 후 불필요한 항목을 제외한 값. 좀 더 정확한 정의가 필요.
      priceFieldMap: {},
      renewPriceTargetShop: null,
    }
  },
  async created() {
    this.priceFieldMap = this.$utils.arr2map(this.priceFields);

    const meta = await this.$api.getMeta('brand,category');
    if (!meta) return;

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, text: `${e.brand_no}. ${e.disabled ? '[미사용] ' : ''}${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => (a.disabled ? 10000 : 0) + a.brand_no - (b.disabled ? 10000 : 0) - b.brand_no);

    this.category = meta.category.filter(e => !e.category.match(/^...(00[178]|111)/)).map(e => {
      return this.categoryMap[e.category] = {
        ...e,
        value: e.category,
      };
    }).sort((a, b) => a.value.localeCompare(b.value));

    // 한번 더 루프를 돌아 full name 을 확보한다.
    this.category.forEach(e => {
      e.label = `${Array(e.category.length / 3).fill(0).map((n, i) => this.categoryMap[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ')} (${e.category})`;
    });
  },
  async mounted() {
    let elem = document.createElement('script');
    elem.setAttribute('src', "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");
    document.head.appendChild(elem);
  },
  watch: {
    tabIndex() {
      this.$forceUpdate();
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    // chkImgOcr: {
    //   get() {
    //     return !!this.item.imageDropOcrThreshold;
    //   },
    //   set() {
    //   }
    // },
    chkImgRatio: {
      get() {
        return !!this.item.longImageToDescriptionRatio;
      },
      set() {
      }
    }
  },
  methods: {
    init(item) {
      item.base_expected_settlement_date = item.base_expected_settlement_date || 'confirmation';
      item.orderMade = item.orderMade || false;
      this.item_org = this.$utils.clone(item);
      this.item = this.$utils.clone(item);

      // const sales = ['sales', 'abroad_sales', 'domestic_sales', 'domestic1', 'domestic2', 'sales_operator'];
      // const op = ['operation', 'op_operator'];
      const mdOpTeams = [
        // 기존 md_id
        'eMEVPZ2m0D', // 글로벌커머스실
        'k1EBdZPY0y', // 로컬B2B사업실
        'bQ8XrZPjzX', // 글로벌B2B사업실
        // 'p1EDdZKdEY', // 마케팅전략실
        '7907y4emEg', // 도메스틱커머스실
        'w303MBbvED', // 영업총괄팀
        'PM0v4RmeEX', // 영업전략실

        // 기존 op_id
        '5M0nwepNz6', // B2B그룹
        // 'eMEVPZ2m0D', // 글로벌커머스실
        'xBEYKZbY0g', // PMO그룹
        'pVEkGny7EM', // 운영그룹
        // '7907y4emEg', // 도메스틱커머스실
        '4bEmm97GED', // Business Operations실
        'jY0rp3JPE1', // Global Business팀

        // CR 그룹
        '3xzLDZjYzO', // Connected Retail그룹
        '6xzOeZoG8w', // MD/VMD팀
        'ODzZGZnVER', // 고객경험총괄팀
        '210PDZrP0x', // 마케팅총괄팀
        'w303MBbvED', // 영업총괄팀
      ];
      const devTeams = [
        'WL8JXZLjzK', // R&D그룹
        'eR0xj5pZEm', // 솔루션플랫폼실
        '3xzLDZkpzO', // 커머스플랫폼실
      ];
      this.users.md = this.options.users.filter(e => mdOpTeams.includes(e.teamId));
      this.users.op = this.options.users.filter(e => mdOpTeams.includes(e.teamId));
      this.users.dev = this.options.users.filter(e => devTeams.includes(e.teamId));

      this.show = true;
    },
    async setFixedComp(comp) {
      this.item.fixed_del_company = comp;
      this.$forceUpdate();
    },
    async removeShop(shop_id) {
      if (!confirm(`정말로 ${shop_id} SHOP 을 삭제하시겠습니까?`)) return;
      let j = await this.$api.postJson('/shop/removeShop', {item: {shop_id}});
      if (j) {
        this.$emit('refreshList');
        this.show = false;
      }
    },
    showDiffModal(item) {
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: item, item_org: this.item_org, db: 'hub', collection: 'meta_shops'});
    },
    async modalHide(event) {
      let modifiedCols = Object.keys(this.item).filter(e => JSON.stringify(typeof this.item_org[e] === 'string' ? this.item_org[e].trim() : this.item_org[e])
          !== JSON.stringify(typeof this.item[e] === 'string' ? this.item[e].trim() : this.item[e]));
      if (event.trigger === 'ok') {
        if (this.modalMode === 'add' || modifiedCols.length) {
          event.preventDefault && event.preventDefault();
          let item = {};

          if (this.modalMode === 'add') {
            item = this.item;
          } else if (modifiedCols.length) {
            item.shop_id = this.item.shop_id;
            modifiedCols.forEach(e => item[e] = typeof this.item[e] === 'string' ? this.item[e].trim() : this.item[e]);
          }

          if (modifiedCols.includes('settlement_type') && this.item.goodsType !== 'used' && item.settlement_type === 'g_type') {
            if (!confirm(`상품 유형이 중고 타입이 아닙니다. 수정하시겠습니까?`)) return false;
          }

          // shop info validation check!
          if (!this.checkValidationOfShopInfo(item)) {
            return;
          }
          const targetUrl = this.modalMode === 'edit' ? 'updateShop' : 'addShop';

          let j = await this.$api.postJson('/shop/' + targetUrl, {item});
          if (item.is_test_shop === true) {
            this.$api.postJson('/shop/updateTestShop', {shop_id: item.shop_id});
          }
          if (j) {
            // 가격요소가 바뀌었는지 체크
            if (modifiedCols.some(c => this.priceFieldMap[c])) {
              this.modal.renewPrice = true;
              this.renewPriceTargetShop = item.shop_id;
            }

            this.$emit('refreshList');
            this.show = false;
            this.use_yn_reason = false;
          }
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        if (modifiedCols.length) {
          // console.log(modifiedCols);
          // modifiedCols.forEach(e=>{
          //   console.log(e, this.item[e], this.item_org[e]);
          // });
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            this.modal.diff = true;
            this.diffHtml = modifiedCols.map(e => `<span class="badge badge-light">${e}</span>
                ${this.item_org[e] === undefined ? '값 없음' : JSON.stringify(this.item_org[e])} → ${JSON.stringify(this.item[e])}`).join('<br/>');
            event.preventDefault && event.preventDefault();
            // if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
            //   event.preventDefault();
            // }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    hideModal() {
      this.show = false;
    },
    async updateShop() {
      let modifiedCols = Object.keys(this.item).filter(e => JSON.stringify(typeof this.item_org[e] === 'string' ? this.item_org[e].trim() : this.item_org[e])
          !== JSON.stringify(typeof this.item[e] === 'string' ? this.item[e].trim() : this.item[e]));
      let item = {};
      item.shop_id = this.item.shop_id;
      modifiedCols.forEach(e => item[e] = typeof this.item[e] === 'string' ? this.item[e].trim() : this.item[e]);

      let j = await this.$api.postJson('/shop/updateShop', {item});
      if (item.is_test_shop === true) {
        this.$api.postJson('/shop/updateTestShop', {shop_id: item.shop_id});
      }
      if (j) {
        this.$emit('refreshList');
        this.show = false;
        this.modal.use_yn_reason = false;
      }
    },
    async updateUseYn() {
      const use_yn = this.item.use_yn === 'y' ? 'n' : 'y';
      if (use_yn === 'n') {
        if (!this.item.use_yn_reason || !this.item.use_yn_reason_detail.trim()) {
          return alert('미사용 처리 사유를 입력해야합니다.');
        }
        if (this.item.use_yn_reason_detail && this.item.use_yn_reason_detail.length > 100) {
          return alert('미사용 처리 사유를 100자 이내로 입력해주세요.');
        }
      } else {
        this.item.use_yn_reason_detail = '';
        this.item.use_yn_reason = '';
        this.item.use_yn_dt = '';
      }
      this.modal.use_yn_reason = false;
      alert(`해당 shop의 사용여부가 ${use_yn === 'y' ? '사용' : '미사용'}으로 변경되었습니다.`);
      this.item.use_yn = use_yn;
      this.$forceUpdate();
    },
    preset(type) {
      let obj = this.$C.SHOP_PRESET[type];
      this.item = Object.assign({}, this.item, obj);
      // 아래의 방법으로는 component 에 render 이벤트를 일으킬 수 없다.
      // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      // Object.entries(obj).forEach(([k,v])=>{
      //   this.item[k] = v;
      // });
    },
    addCategoryId() {
      if (!this.selectedCategory) return alert('카테고리를 선택해주세요');
      const category = this.selectedCategory.category
      if (!this.categoryId) return alert('담당자를 선택해주세요');

      if (this.categoryIdModalType === '영업') {
        this.item.categoryMdId = this.item.categoryMdId || [];
        this.item.categoryMdId.push({category: category, id: this.categoryId});
      } else if (this.categoryIdModalType === '운영') {
        this.item.categoryOpId = this.item.categoryOpId || [];
        this.item.categoryOpId.push({category: category, id: this.categoryId});
      }
      this.selectedCategory = null;
      this.categoryId = '';
      this.modal.categoryId = false;
      this.$forceUpdate();
    },
    removeCategoryMdId(idObj) {
      this.item.categoryMdId.splice(this.item.categoryMdId.indexOf(idObj), 1);
      this.$forceUpdate();
    },
    removeCategoryOpId(idObj) {
      this.item.categoryOpId.splice(this.item.categoryOpId.indexOf(idObj), 1);
      this.$forceUpdate();
    },
    checkHavingContactForDirect() {
      if (this.item.direct_return_yn === "y" && !this.item.fixed_del_company) {
        alert('직접반품사의 경우 지정 택배사가 필요합니다.');
        return false;
      }
      // CS 담당자가 반품 관련 처리 진행 (배송 담당자는 반품 이외의 배송까지만 의미)
      const return_mobile = this.item.manager ? this.item.manager.cs_mobile : this.item.contact;
      if (this.item.direct_return_yn === "y" && (!this.item.return_address || !return_mobile)) {
        alert('직접반품사의 경우 정확한 연락처와 반품주소 설정이 필요합니다.');
        return false;
      }
      return true;
    },
    checkValidationOfShopInfo(item) {
      if (!this.checkHavingContactForDirect()) {
        return false;
      }

      if (this.modalMode === 'edit') {
        // 삭제 가능한 필수 항목
        const required = {
          boutique: 'Shop Name',
          shop_country: '국가',
          delivery_day: '평균배송일',
          exchange_cost: '교환배송비',
          return_cost: '반품배송비',
        };
        if (item.delivery_type === 'both') {
          Object.assign(required, {
            delivery_day_abroad: '평균배송일(해외)',
            exchange_cost_abroad: '교환배송비(해외)',
            return_cost_abroad: '반품배송비(해외)',
          });
        }

        let empty = [];
        Object.entries(item).forEach(([k, v]) => {
          // 수정된 필수 항목에 값이 빈경우
          if (required[k] !== undefined && v === '') {
            empty.push(required[k]);
          }
        });
        if (empty.length) {
          alert(`다음 정보를 입력해주세요.\n\n${empty.join(', ')}`);
          return false;
        }
        // 수정된 배송유형이 both가 아닌 경우 (both -> non both, non-both -> non-both)
        if (this.item_org.delivery_type !== this.item.delivery_type && this.item.delivery_type !== 'both') {
          if (this.$R('ADMIN') && this.$R('DEV')) {
            if (!confirm(`배송유형 변경이 불가한 케이스입니다. 상품이 해당 유형만 존재하는지 확인 후 수정을 진행해주세요. 수정하시겠습니까?`)) return false;
          } else {
            alert(`배송유형 변경이 불가한 케이스입니다. IT 팀에 문의해주세요.`);
            return false;
          }
        }
        if (this.item_org.use_yn !== this.item.use_yn && this.item.use_yn === 'n' && (!this.item.use_yn_reason || !this.item.use_yn_reason_detail.trim())) {
          alert('미사용 처리 사유를 입력해야합니다.');
          return false;
        }
        // 배송유형과 같이 상품 판매 유형 역시 both 타입이 아닌 유형으로 변경 시 제한한다.
        if (this.item_org.goodsType !== this.item.goodsType && this.item.goodsType !== 'both') {
          alert(`상품 판매 유형 직접 변경이 불가하며 개발팀으로 문의 주세요.`);
          return false;
        }

      } else {
        // 필수 정보 체크
        const required = {
          // shop_id: 'Shop ID', // -> 자동 생성
          boutique: 'Shop Name',
          use_yn: '사용중 여부',
          shop_type: '유형',
          currency: '통화',
          shop_country: '국가',
          logistics: '직배송여부',
          contract_yn: '계약여부',
          pccc_feed: '개인통관고유부호 수집여부', // #kwon: 필수 체크항목 추가
          pccc_require: '개인통관고유부호 필수여부',  // #kwon: 필수 체크항목 추가
          delivery_type: '배송타입',
          return_ok: '반품가능여부',
          stock_manage: '재고연동',
          delivery_day: '평균배송일',
          exchange_cost: '교환배송비',
          return_cost: '반품배송비',
          md_id: '영업담당',
          price_calculation: '가격계산방식',
          sync_origin: '연동원천',
          fetch_type: '연동유형',
          addr: '주소정보',
          name_open: '파트너 이름 노출',
          goodsType: '상품 판매 유형',
          autoPlaceOrder: '자동 배송준비'
        };
        if (item.delivery_type === 'both') {
          Object.assign(required, {
            delivery_day_abroad: '평균배송일(해외)',
            exchange_cost_abroad: '교환배송비(해외)',
            return_cost_abroad: '반품배송비(해외)',
          });
        }
        if (item.shop_type === 'parallel') {
          Object.assign(required, {rebate_rate: '수수료율'});
        }

        let confirmMsg = []

        const calc_return_cost_abroad =  item.return_cost_type !== 'goods' && item.logistics === 'direct' ? 30000 : 50000;
        const calc_exchange_cost_abroad = item.exchange_cost_type !== 'goods' && item.logistics === 'direct' ? 30000 : 50000;

        let return_cost = 0;
        let exchange_cost = 0;
        let return_cost_abroad = 0;
        let exchange_cost_abroad = 0;

        if (item.delivery_type !== 'abroad') {
          if (!item.return_cost) {
            return_cost = 10000;
            confirmMsg.push(`국내 반품비를 입력하지 않아 ${return_cost}로 설정됩니다.`);
          }
          if (!item.exchange_cost)  {
            exchange_cost = 10000;
            confirmMsg.push(`국내 교환비를 입력하지 않아 ${exchange_cost}로 설정됩니다.`);
          }
          if (item.delivery_type === 'both') {
            if (!item.return_cost_abroad) {
              return_cost_abroad = calc_return_cost_abroad;
              confirmMsg.push(`해외 반품비를 입력하지 않아 ${return_cost_abroad}로 설정됩니다.`);
            }
            if (!item.exchange_cost_abroad) {
              exchange_cost_abroad = calc_exchange_cost_abroad;
              confirmMsg.push(`해외 교환비를 입력하지 않아 ${exchange_cost_abroad}로 설정됩니다.`);
            }
          }
        } else if (item.delivery_type === 'abroad') {
          if (!item.exchange_cost)  {
            exchange_cost = calc_exchange_cost_abroad;
            confirmMsg.push(`해외 교환비를 입력하지 않아 ${exchange_cost}로 설정됩니다.`);
          }
          if (!item.return_cost)  {
            return_cost = calc_return_cost_abroad;
            confirmMsg.push(`해외 반품비를 입력하지 않아 ${return_cost}로 설정됩니다.`);
          }
        }
        if (item.dynamicPricingEnabled && item.currency !== 'KRW') {
          alert('dynamicPricing의 경우 환율이 KRW 일 때만 적용이 가능합니다.');
          return false;
        }

        if (confirmMsg.length) {
          if (!confirm(confirmMsg.join('\n'))) {
            return false; // 이벤트 무시
          }
          item.return_cost = return_cost || item.return_cost;
          item.exchange_cost = exchange_cost || item.exchange_cost;
          item.return_cost_abroad = return_cost_abroad || item.return_cost_abroad;
          item.exchange_cost_abroad = exchange_cost_abroad || item.exchange_cost_abroad;
        }

        let empty = [];
        Object.entries(required).forEach(([k, v]) => {
          if (item[k] == null || item[k] === '') {
            empty.push(v);
            console.log(v, k, item[k], item);
          }
        });
        if (empty.length) {
          alert(`다음 정보를 입력해주세요.\n\n${empty.join(', ')}`);
          return false;
        }
        console.log('empty', empty)
      }
      // 숫자 항목 validation check
      const numberItems = {
        delivery_day: '평균배송일',
        delivery_day_abroad: '평균배송일(해외)',
        exchange_cost: '교환배송비',
        exchange_cost_abroad: '교환배송비(해외)',
        return_cost: '반품배송비',
        return_cost_abroad: '반품배송비(해외)',
        // imageDropOcrThreshold: '상품관리옵션>상세설명이미지제외',
        longImageToDescriptionRatio: '상품관리옵션>긴 이미지를 상세설명으로',
        iva_rate: 'IVA율',
        local_delivery_cost: '가격계산기본>해외명목배송비(EUR)',
        local_delivery_cost_krw: '가격계산기본>해외명목배송비(원)',
        local_delivery_avg_cost_per: '가격계산기본>해외배송비(%)',
        local_delivery_avg_cost_max: '가격계산기본>해외배송비(최대)',
        local_delivery_supply_cost: '가격계산기본>해외실질배송비(EUR)',
        local_delivery_supply_cost_krw: '가격계산기본>해외실질배송비(원)',
        domestic_delivery_cost: '가격계산기본>국내배송비',
        customs_rate: '가격계산기본>일괄관세율',
        tax_included_if_price: '가격계산기본>세금포함조건',
        rebate_rate: '가격계산기본>수수료율',
        rebate_rate_used: '가격계산기본>중고 수수료율',
        // margin_rate: '가격계산기본>일괄마진율',
        // margin_if: '가격계산기본>마진하한조건식(%1)',
        // margin_if_then: '가격계산기본>마진하한조건식(%2)',
        // margin_if_over: '가격계산기본>마진상한조건식(%1)',
        // margin_if_over_then: '가격계산기본>마진상한조건식(%2)',
        apply_consumer_rate: '가격계산상세>현지가격 할인할증(Customer)',
        apply_sale_rate: '가격계산상세>현지가격 할인할증(Sale)',
        apply_newin_rate: '가격계산상세>현지가격 할인할증(NewIn)',
        apply_supply_rate: '가격계산상세>현지가격 할인할증(Supply)',
        apply_consumer_divider: '가격계산상세>현지가격 divider(Customer)',
        apply_sale_divider: '가격계산상세>현지가격 divider(Sale)',
        apply_newin_divider: '가격계산상세>현지가격 divider(NewIn)',
        apply_supply_divider: '가격계산상세>현지가격 divider(Supply)',
        apply_consumer_roundup: '가격계산상세>현지가격 올림 자리수(Customer)',
        apply_sale_roundup: '가격계산상세>현지가격 올림 자리수(Sale)',
        apply_newin_roundup: '가격계산상세>현지가격 올림 자리수(NewIn)',
        apply_supply_roundup: '가격계산상세>현지가격 올림 자리수(Supply)',

        // apply_consumer_partner_pre_amount: '가격계산상세>파트너 선증감액(Customer)',
        // apply_sale_partner_pre_amount: '가격계산상세>파트너 선증감액(Sale)',
        // apply_newin_partner_pre_amount: '가격계산상세>파트너 선증감액(NewIn)',
        // apply_supply_partner_pre_amount: '가격계산상세>파트너 선증감액(Supply)',

        // apply_consumer_operate_rate: '가격계산상세>운영 할인할증(Customer)',
        // apply_sale_operate_rate: '가격계산상세>운영 할인할증(Sale)',
        // apply_newin_operate_rate: '가격계산상세>운영 할인할증(NewIn)',
        // apply_supply_operate_rate: '가격계산상세>운영 할인할증(Supply)',

        // apply_consumer_partner_rate: '가격계산상세>파트너 할인할증(Customer)',
        // apply_sale_partner_rate: '가격계산상세>파트너 할인할증(Sale)',
        // apply_newin_partner_rate: '가격계산상세>파트너 할인할증(NewIn)',
        // apply_supply_partner_rate: '가격계산상세>파트너 할인할증(Supply)',

        // apply_consumer_partner_post_amount: '가격계산상세>파트너 후증감액(Customer)',
        // apply_sale_partner_post_amount: '가격계산상세>파트너 후증감액(Sale)',
        // apply_newin_partner_post_amount: '가격계산상세>파트너 후증감액(NewIn)',
        // apply_supply_partner_post_amount: '가격계산상세>파트너 후증감액(Supply)',

        apply_consumer_krw_rate: '가격계산상세>국내가격 할인할증(Customer)',
        apply_sale_krw_rate: '가격계산상세>국내가격 할인할증(Sale)',
        apply_newin_krw_rate: '가격계산상세>국내가격 할인할증(NewIn)',
        apply_supply_krw_rate: '가격계산상세>국내가격 할인할증(Supply)',
        apply_consumer_krw_divider: '가격계산상세>국내가격 divider(Customer)',
        apply_sale_krw_divider: '가격계산상세>국내가격 divider(Sale)',
        apply_newin_krw_divider: '가격계산상세>국내가격 divider(NewIn)',
        apply_supply_krw_divider: '가격계산상세>국내가격 divider(Supply)',
      }
      const nanItems = Object.keys(this.item).filter(key => numberItems[key]
          && this.item[key] != null && this.item[key] !== '' && !this.$utils.isNumeric(this.item[key]));
      const nanValues = nanItems.map(x => numberItems[x])
      if (nanValues.length > 0) {
        alert(`다음 정보는 숫자만 입력해 주세요.\n\n${nanValues.join(', ')}`);
        return false;
      }

      // 정수 체크
      if (this.item.delivery_day && this.item.delivery_day % 1) alert(`평균배송일은 정수로 입력해 주세요.`);
      if (this.item.delivery_day_abroad && this.item.delivery_day_abroad % 1) alert(`평균배송일(해외)은 정수로 입력해 주세요.`);

      // 220825 이후 병행일 경우 다음 룰 적용
      // apply_consumer_rate, apply_*_divider, apply_*_krw_rate, apply_*_krw_divider 는 수수료가 없는 경우 사용하지 않는다.
      // apply_newin_rate 는 apply_sale_rate 와 동일한 값을 갖는다.
      // apply_sale_rate 는 apply_supply_rate 와 같거나 커야 한다. 값을 갖는다.
      if (this.item.shop_type === 'parallel') {
        if (this.item.apply_newin_rate !== this.item.apply_sale_rate) {
          return alert('현지가격 할인할증(NewIn) 은 현지가격 할인할증(Sale) 과 동일해야 합니다');
        } else if (this.item.apply_sale_rate < +this.item.apply_supply_rate - 2) {
          return alert('현지가격 할인할증(Sale) 은 [현지가격 할인할증(Supply) - 2%] 와 같거나 큰 값이어야 합니다');
        // } else if (this.item.apply_newin_operate_rate !== this.item.apply_sale_operate_rate) {
        //  return alert('운영 할인할증(NewIn) 은 운영 할인할증(Sale) 과 동일해야 합니다');
        // } else if (this.item.apply_newin_partner_rate !== this.item.apply_sale_partner_rate) {
        //   return alert('파트너 할인할증(NewIn) 은 파트너 할인할증(Sale) 과 동일해야 합니다');
        // } else if (this.item.apply_sale_operate_rate < +this.item.apply_supply_operate_rate) {
        //   return alert('운영 할인할증(Sale) 은 운영 할인할증(Supply) 과 같거나 큰 값이어야 합니다');
        }
        const targetKeys = Object.keys(numberItems).filter(e => e.match(/apply_.*(_divider|_krw_rate)|apply_consumer_rate/));
        if (this.item.rebate_rate && targetKeys.some(e => this.item[e]) && !this.$R('ADMIN')) {
          return alert('할인할증 이나 divider 는 현지가격 외에는 설정할 수 없습니다');
        }
      }

      // 배송유형에 따라 국내 2만원, 해외 10만원 상한
      const domestic_cost = 20000;
      const abroad_cost = this.item.logistics === 'balaan' ? 50000 :100000

      if (this.item.delivery_type === 'both') {
        if (item.return_cost > domestic_cost) return alert(`국내 배송의 반품배송비는 ${this.$utils.comma(domestic_cost)} 원 이하로 설정이 가능합니다`);
        if (item.exchange_cost > domestic_cost) return alert(`국내 배송의 교환배송비는 ${this.$utils.comma(domestic_cost)} 원 이하로 설정이 가능합니다`);
        if (item.return_cost_abroad > abroad_cost) return alert(`해외 배송의 반품배송비는 ${this.$utils.comma(abroad_cost)} 원 이하로 설정이 가능합니다`);
        if (item.exchange_cost_abroad > abroad_cost) return alert(`해외 배송의 교환배송비는 ${this.$utils.comma(abroad_cost)} 원 이하로 설정이 가능합니다`);
      } else if (this.item.delivery_type === 'domestic') {
        if (item.return_cost > domestic_cost) return alert(`국내 배송의 반품배송비는 ${this.$utils.comma(domestic_cost)} 원 이하로 설정이 가능합니다`);
        if (item.exchange_cost > domestic_cost) return alert(`국내 배송의 교환배송비는 ${this.$utils.comma(domestic_cost)} 원 이하로 설정이 가능합니다`);
      } else if (this.item.delivery_type === 'abroad') {
        if (item.return_cost > abroad_cost) return alert(`해외 배송의 반품배송비는 ${this.$utils.comma(abroad_cost)} 원 이하로 설정이 가능합니다`);
        if (item.exchange_cost > abroad_cost) return alert(`해외 배송의 교환배송비는 ${this.$utils.comma(abroad_cost)} 원 이하로 설정이 가능합니다`);
      }

      return true;
    },
    inputReason(text) {
      if (text.length > 100) {
        this.item.use_yn_reason_detail = text.slice(0, 100);
      }
    },
    async renewPriceShop() {
      this.modal.renewPrice = false;
      const j = await this.$api.postJson('/price/renewPriceAll', {shop_id: [this.renewPriceTargetShop], from: location.href});
      if (j) {
        this.$modal.show({title: '가격갱신 시작', html: '가격갱신이 백그라운드에서 시작되었습니다. 가격 갱신 메뉴의 결과를 확인해주세요.'});
      }
    },
    changeShopUseYN() {
      this.modal.use_yn_reason = true;
    },
    addBrandModal(type) {
      if (type === 'brandWhitelist'?
          this.item.brandBlackList && this.item.brandBlackList.length :
          this.item.brandWhitelist && this.item.brandWhitelist.length) {
        return alert('판매 가능/제한 브랜드 설정은 하나의 기능만 사용할 수 있습니다.');
      }

      const popup = localStorage.getItem('popup:shopBrandFirstSet');
      const list = this.item[type] || [];
      if (!list.length && (!popup || popup < +new Date)) {
        this.modal.brandFirstSet = true;
        return;
      }
      this.selectedBrand = null;
      this.brandListType = type;
      this.modal.brandListSet = true;
    },
    addBrand(brandNo) {
      this.item[this.brandListType].push({brand_no: brandNo});
      this.item_org[this.brandListType] = this.item_org[this.type] || [];
      this.item_org[this.brandListType].push({brand_no: brandNo}); // 브랜드는 shop 저장과 별도로 운영된다. 삭제시 상품 즉시 삭제와 연관이 있기 때문
      this.$forceUpdate();
    },
    // 판매 가능 브랜드를 제거한다. 상품이 있는지 확인해야 한다.
    async removeBrandModal(type, brandObj) {
      const form = {};
      form.brandNo = brandObj.brand_no;
      form.brandNm = this.brandMap[brandObj.brand_no].brand_nm_kr;
      this.selectedBrand = form;
      this.brandListType = type;
      this.modal.removeBrand = true;
    },
    removeBrand(brandNo) {
      const index = this.item.brandWhitelist.indexOf(brandNo);
      this.item[this.brandListType].splice(index, 1);
      this.item_org[this.brandListType].splice(index, 1);
      this.$forceUpdate();
    },
    setPcccRequire() {
      this.item.pccc_require = this.item.pccc_feed === 'y' ? 'y' : 'n';
    }
  }
}
</script>
