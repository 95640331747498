<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="6" lg="3">
        <small>가격계산</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.price_calculation" :options="[
                    {text: '자동', value: 'auto'},
                    {text: '수동', value: 'manual'}
                  ]">
        </b-form-radio-group>
      </b-col>
      <b-col cols="6" lg="3">
        <small>IVA 율(비워두면 {{priceMap.iva_rate}}%)</small><br/>
        <b-form inline>
          <b-form-input class="w-100px" v-model.number="item.iva_rate"></b-form-input> %
        </b-form>
      </b-col>
      <b-col cols="12" lg="6">
        <small>NewIn 시즌</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'해당 시즌 상품의 판매가는 NewIn 계산 결과를 사용합니다'"></i><br/>
        <b-form-input v-model="item.newin_season" placeholder="20SS,20FW 형태로 콤마를 사이에 두고 입력해주세요"></b-form-input>
      </b-col>
    </b-row>

    <div class="title-sm">판매 가능 상품 유형</div>
    <b-row>
      <b-col>
        <b-form-radio-group
          class="col-form-label"
          v-model="item.goodsType"
          :options="options.goodsType">
        </b-form-radio-group>
      </b-col>
    </b-row>

    <div class="title-sm">
      <div>판매 가능 카테고리</div>
      <small>판매 가능 카테고리를 선택하시면 [파트너센터]에서 상품등록시 카테고리 선택이 가능합니다.</small>
    </div>
    <b-row>
      <b-col>
        <b-form-checkbox-group
          v-model="item.categoryWhiteList"
          :options="[
            ...this.category.filter(i => i.category.length === 3).map(i => ({
              text: i.category_nm,
              value: i.category,
            })),
          ]">
        </b-form-checkbox-group>
      </b-col>
    </b-row>

    <div v-if="item.consignUsed === true">
      <div class="title-sm">
        위탁 수수료
        <i class="fa fa-question-circle ml-1" v-b-tooltip="`위탁상품 판매가능 Shop 이 'Y' 인 경우 위탁 대행사 수수료율을 입력해주세요. 위탁 판매자 수수료율 설정은 개발팀에 문의해주세요.`"></i><br/>
      </div>
      <b-row>
        <b-col class="mb-2" cols="6" lg="3">
          <small>위탁 대행사 수수료율</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'발란 -> 위탁 대행사에 제공하는 수수료율 입니다.'"></i><br/>
          <b-form inline>
            <b-input class="w-80px text-center" type="number" v-model.number="item.consignUsedAmount"></b-input>원 + &nbsp;
            <b-input class="w-70px text-center" type="number" v-model.number="item.consignUsedRate"></b-input>%
          </b-form>
        </b-col>
      </b-row>
      <div class="pull-right mt-n3">
        <b-form inline>
          [수수료 계산] 중고상품 판매가 : &nbsp;<b-input class="w-100px text-right" v-model.number="consignPrice"></b-input>
        </b-form>
      </div>
      <small>위탁 판매자 수수료율</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'중고 위탁 판매 상품에 대한 수수료율 입니다.'"></i><br/>
      <b-row>
        <b-col v-for="(r, idx) in consignArr" cols="12" class="mb-1">
          <div :key="idx">
            <div class="pull-right">
              <b-input class="w-100px text-right" v-model.number="r.sampleAmount" disabled></b-input>
            </div>
            <b-form inline>
              <div class="d-inline-block" style="width: 280px">
                <template v-if="r.amount">
                  기본 수수료
                  <b-input class="w-70px text-right" v-model.number="r.amount" disabled></b-input>
                  원 + 구간별 수수료
                </template>
              </div>
              <div class="d-inline-block" style="width: 323px">
                <b-input class="w-100px text-right" v-model.number="r.gt" disabled></b-input>
                원 초과
                <template v-if="r.lte !== 9999999999">
                  ~
                  <b-input class="w-100px text-right" v-model.number="r.lte" disabled></b-input>
                  원 이하
                </template>
              </div>
              <div class="d-inline-block">
                <b-input class="w-60px text-right" v-model.number="r.rate" disabled></b-input>
                %
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
      <div class="clearfix">
        <div class="pull-right">
          <b-input class="w-100px text-right" v-model.number="consignAmountSum" disabled></b-input>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="title-sm">수수료</div>
      <b-row>
        <b-col class="mb-2" cols="12" lg="12">
          <div class="mb-2">
            기본 수수료율
            <small>판매 가능 카테고리에 일괄 적용되는 수수료</small>
          </div>
          <b-form inline>
            <b-input class="w-60px text-center" v-model.number="item.rebate_rate">
            </b-input>
            <span class="ml-1">%</span>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-2" cols="12" lg="12">
          <div class="mb-2">
            빈티지 수수료율
            <small>판매 가능 카테고리에 빈티지 상품에만 적용되는 수수료 (비워둘 경우 빈티지 상품에도 기본 수수료율이 적용됩니다.)</small>
          </div>
          <b-form inline>
            <b-input class="w-60px text-center" v-model.number="item.rebate_rate_used">
            </b-input>
            <span class="ml-1">%</span>
          </b-form>
        </b-col>
      </b-row>

      <div class="title-sm">카테고리 한정 수수료 <i class="fa fa-exclamation-circle" v-b-tooltip="'변경사항이 있을 경우 관련상품의 가격을 갱신해야 합니다'"></i></div>
      <div class="clearfix mb-2" style="margin-top: -40px">
        <b-btn class="pull-right" size="sm" variant="success" @click="modal.fee = true">추가</b-btn>
      </div>
      <div v-if="!item.categoryFee || item.categoryFee.length === 0" class="py-2">
        카테고리 한정 수수료 설정이 없습니다.
      </div>
      <b-row v-else>
        <b-col class="py-2" cols="12" v-for="e in item.categoryFee" :key="e.category">
          {{ (categoryMap[e.category] || {}).label || '' }} : <b-badge variant="primary">{{e.rate}} %</b-badge>
          <b-badge class="pull-right" variant="danger"><i class="fa fa-close pointer" @click="removeCategoryFee(e)"></i></b-badge>
        </b-col>
      </b-row>
      <b-modal title="카테고리 한정 수수료 추가" size="lg" v-model="modal.fee">
        <b-alert variant="warning" show>
          카테고리 한정 수수료는 상위 카테고리가 하위 카테고리를 포함하며 하위카테고리가 우선순위를 지닙니다.<br/>
          ex) 009002 : 10%, 009002003 : 11% 일 때 009002003 상품은 11%, 009002004 상품은 10%
        </b-alert>
        <b-row class="mt-2">
          <b-col cols="10">
            <v-select v-model="selectedCategory" :options="category"></v-select>
          </b-col>
          <b-col cols="2">
            <b-form inline>
              <b-input v-model.number="rate" class="w-65px text-center"></b-input> %
            </b-form>
          </b-col>
        </b-row>
        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="primary" @click="addCategoryFee()">
            추가
          </b-button>
          <b-button variant="secondary" @click="cancel()">
            취소
          </b-button>
        </template>
      </b-modal>
    </div>


    <div class="title-sm" :class="item.dynamicPricingEnabled ? 'text-danger' : ''">
      Dynamic Pricing
      (현재세팅: {{ item.dynamicPricingVer ? '파트너등급제[`23.11 이후] 버전' : '다이나믹 프라이싱[`23.10 까지] 버전' }})
    </div>
    <b-alert v-if="item.dynamicPricingVer" class="position-relative" variant="info" show>
      <b-button class="position-absolute" style="right: 15px" size="sm" variant="success" @click="$utils.open('https://www.notion.so/Dynamic-Pricing-3d35e2a012e1428d9c747f8862746d3c?pvs=4')">가이드</b-button>
      <b>1차 상시 할인 B Rank Class 별 분담률</b>
      <i class="fa fa-question-circle ml-1" v-b-tooltip="'1차 상시할인 B class 별 분담률은 전체 SHOP에 공통 적용되는 항목입니다.'"></i>
      <br/>
      - B Rank Class 1위 단독일 경우 발란 분담률은 50% 동률일 경우 발란 분담률은 30% 입니다.<br/>
      - 그 외 B Rank Class 단독/동률 일 경우 발란 분담률은 30% 입니다.<br/>
      * N위의 X개의 상품이 동률 일 경우, 다음 순위는 N+X위 입니다.<br/>
    </b-alert>
    <b-row class="mb-2">
      <b-col cols="6" md="3">
        <small>사용여부</small>
        <i class="fa fa-question-circle ml-1 pointer" :title="'Dynamic Pricing 사용 여부 체크 시,\n'+
          'Standard Price와 (발란추천가 - 1000 원 후 1원 단위 내림) 가격을 비교 후 차액을 계산합니다.\n'+
          '차액이  0원 이하 일 경우(발란추천가 기달성) Dynamic Pricing에 셋팅한 할인률은 적용되지않습니다.\n'+
          '차액을 Standartd Price와 비교하여 %를 구합니다.\n'+
          '%가 Dynamic Pricing에서 셋팅한 최대 할인률 이하인 경우 DP가 작동합니다. (=상태값 : Positive) \n'+
          'DP는 1차 상시할인 -> 2차 비상시 할인 -> 3차 발란 단독할인 순으로 할인액을 적용합니다. \n'+
          '차액%가  Dynamic Pricing에서 셋팅한 최대 할인률 초과인 경우( =상태값 : Negative) 추천가 달성 불가능시 영역에 셋팅한 고정  된 할인율을 적용합니다. \n'+
          '발란추천가가 없는 경경우( =상태값 : Fixed) 추천가 달성 불가능시 영역에 셋팅한 고정  된 할인율을 적용합니다. '"></i><br/>
        <b-form-checkbox class="col-form-label" v-model="item.dynamicPricingEnabled" :disabled="item.currency !== 'KRW'">사용</b-form-checkbox>
      </b-col>
      <b-col cols="6" md="2">
        <small>발란추천가 차액 기준</small>
        <i class="fa fa-question-circle ml-1" v-b-tooltip="'발란추천가 달성 여부를 판단하는 기준입니다. (달성가능 시 최대할인율과 동일)'"></i>
        <br/>
        <b-form inline>
          <span class="bold col-form-label">{{dpPositiveRate}} %</span>
        </b-form>
      </b-col>
      <b-col cols="12" md="2">
        <small>추천가 대비 갭</small>
        <i class="fa fa-question-circle ml-1" v-b-tooltip="item.dynamicPricingVer ? '목표금액을 추천가보다 1000원 낮춘 뒤, 10 원 단위로 가격을 버림합니다.' :
         'SHOP 공통 적용되는 추천가 대비 갭 입니다.'"></i>
        <br/>
        <div v-if="item.dynamicPricingVer" class="col-form-label">
          발란추천가 -1000원
        </div>
        <b-form v-else inline>
          <b-form-input class="w-65px" v-model.number="item.dynamicPricingGap" :disabled="!item.dynamicPricingEnabled"></b-form-input>&nbsp;원
        </b-form>
      </b-col>
      <b-col v-if="item.dynamicPricingVer" cols="12" md="3">
        <small>분담형 할인 ((구)2차 비상시 할인)의 분담율</small>
        <i class="fa fa-question-circle ml-1" v-b-tooltip="'2차 비상시 할인율의 발란 분담률을 입력하는 컬럼입니다. 설정하지 않으면 50% 로 적용됩니다.'"></i>
        <br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpCondBalaanRatio" :disabled="!item.dynamicPricingEnabled"></b-form-input>&nbsp;%
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="6" md="3">
        <span class="bold col-form-label text-success">발란추천가 존재하며 달성 가능 시</span>
        <b-form class="mt-1" inline>
          (<span class="bold">최소할인율</span>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'상품의 가격이 발란추천가보다 낮아도 최소한 적용되는 할인율입니다.'"></i>
          <b-form-input size="sm" class="w-65px mx-2" v-model.number="item.dpMinRate" :disabled="!item.dynamicPricingEnabled"></b-form-input>%)
        </b-form>

      </b-col>
      <b-col cols="6" md="2">
        <small>최대 할인율</small><br/>
        <b-form inline>
          <span class="bold col-form-label">{{dpPositiveRate}} %</span>
        </b-form>
      </b-col>
      <b-col cols="6" md="2">
        <span style="letter-spacing: -0.5px;">
          <small>(구)1차 상시 할인률<br/>(B rank에 따라 분담률 상이)</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'B Rank Class 에 따라 분담율은 상이합니다.'"></i>
        </span>
        <br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpPositiveBaseRate" disabled></b-form-input>&nbsp;%
        </b-form>
      </b-col>
      <b-col cols="6" md="2">
        <small>분담형 할인율<br/>((구)2차 비상시 할인률)</small><br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpPositiveCondRate" :disabled="!item.dynamicPricingEnabled"></b-form-input>&nbsp;%
        </b-form>
      </b-col>
      <b-col cols="6" md="3">
        <small>발란 단독 할인률<br/>((구)3차 발란 단독 할인)</small><br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpPositiveBalaanRate" :disabled="!item.dynamicPricingEnabled"></b-form-input>&nbsp;%
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="6" md="3">
        <span class="bold col-form-label text-warning">발란추천가 존재하며 달성 불가능 시<br/><span v-if="item.dynamicPricingVer">(+발란추천가 없을시)</span></span>
      </b-col>
      <b-col>
        <small>최대 할인율</small><br/>
        <b-form inline>
          <span class="bold col-form-label">{{dpNegativeRate}} %</span>
        </b-form>
      </b-col>
      <b-col cols="6" md="2">
        <span style="letter-spacing: -0.5px;">
          <small>(구)1차 상시 할인률<br/>(B rank에 따라 분담률 상이)</small>
          <i class="fa fa-question-circle ml-1" v-b-tooltip="'B Rank Class 에 따라 분담율은 상이합니다.'"></i>
        </span>
        <br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpNegativeBaseRate" disabled></b-form-input>&nbsp;%
        </b-form>
      </b-col>
      <b-col cols="6" md="2">
        <small>분담형 할인율<br/>((구)2차 비상시 할인률)</small><br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpNegativeCondRate" :disabled="!item.dynamicPricingEnabled"></b-form-input>&nbsp;%
        </b-form>
      </b-col>
      <b-col cols="6" md="3">
        <small>발란 단독 할인률<br/>((구)3차 발란 단독 할인)</small><br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.dpNegativeBalaanRate" :disabled="!item.dynamicPricingEnabled"></b-form-input>&nbsp;%
        </b-form>
      </b-col>
    </b-row>

    <div class="title-sm">배송비</div>
    <b-row class="mb-2">
      <b-col lg="3">
        <small>해외 명목 배송비(Consumer)</small><br/>
        <b-form inline>
          <b-form-radio v-model="item.local_delivery_cost_type" name="local_delivery_cost_type" value="local"></b-form-radio>
          <b-form-input class="w-65px" v-model.number="item.local_delivery_cost" @focus="localDeliveryFocus('local')"></b-form-input>&nbsp;{{item.currency}}&nbsp;&nbsp;
          <b-form-radio v-model="item.local_delivery_cost_type" name="local_delivery_cost_type" value="krw"></b-form-radio>
          <b-form-input class="w-65px" v-model.number="item.local_delivery_cost_krw" @focus="localDeliveryFocus('krw')"></b-form-input>&nbsp;원
        </b-form>
      </b-col>
      <b-col lg="3">
        <small>해외 배송비(Sale, Avg)</small><br/>
        <b-form inline>
          <b-form-input class="w-65px" placeholder="" v-model.number="item.local_delivery_avg_cost_per"></b-form-input>
          % (최대
          <b-form-input class="w-65px" :placeholder="priceMap.delivery_cost_kr+''" v-model.number="item.local_delivery_avg_cost_max"></b-form-input>&nbsp;원)
        </b-form>
      </b-col>
      <b-col lg="3">
        <small>해외 실질 배송비(Supply)</small><br/>
        <b-form inline>
          <b-form-radio v-model="item.local_delivery_supply_cost_type" name="local_delivery_supply_cost_type" value="local"></b-form-radio>
          <b-form-input class="w-65px" v-model.number="item.local_delivery_supply_cost" @focus="localDeliveryFocus('local', 'supply')"></b-form-input>&nbsp;{{item.currency}}&nbsp;&nbsp;
          <b-form-radio v-model="item.local_delivery_supply_cost_type" name="local_delivery_supply_cost_type" value="krw"></b-form-radio>
          <b-form-input class="w-65px" v-model.number="item.local_delivery_supply_cost_krw" @focus="localDeliveryFocus('krw', 'supply')"></b-form-input>&nbsp;원
        </b-form>
      </b-col>
      <b-col lg="3">
        <small>국내 배송비(비워두면 {{$utils.rnc(priceMap.domestic_box_delivery)}}원)</small><br/>
        <b-form inline>
          <b-form-input class="w-100px" v-model.number="item.domestic_delivery_cost"></b-form-input>&nbsp;원
        </b-form>
      </b-col>
    </b-row>

    <div class="title-sm">세금</div>
    <b-row>
      <b-col class="mb-2" cols="6" lg="3">
        <small>최초가격에 관세를 제함</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.remove_customs_on_rebate" :options="options.yn"></b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>최초가격에 부가세를 제함</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.remove_vat_on_rebate" :options="options.yn"></b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>일괄관세</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.fixed_customs_yn" :options="options.yn" @change="()=>{ if (item.fixed_customs_yn === 'y') item.customs_rate = ''; }">
        </b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>일괄관세율</small><br/>
        <b-form inline>
          <b-form-input class="w-65px" v-model.number="item.customs_rate"></b-form-input>%
        </b-form>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>해외배송비에도 관부가세</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.customs_vat_with_delivery_yn" :options="options.yn">
        </b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>FTA(관세 0%)</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.fta_yn" :options="options.yn"></b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>개별소비세 및 교육세</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.idv_tax_yn" :options="options.yn"></b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>관세, 개소세에 부가세</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.vat_with_idv_tax_yn" :options="options.yn"></b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>가격에 관세, 개소세가 포함됨</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.customs_included" :options="options.yn"></b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>가격에 부가세가 포함됨</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.vat_included" :options="options.yn"></b-form-radio-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-2" cols="6" lg="3">
        <small>가격조건부로 세금이 포함됨</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.tax_included_by_price" :options="options.yn" @change="()=>{ if (item.tax_included_by_price === 'y') item.tax_included_if_price = ''; }">
        </b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="12" lg="6">
        <small>세금 포함 조건</small><br/>
        <b-form inline>
          가격이 &nbsp;<b-input v-model.number="item.tax_included_if_price" class="w-50px"></b-input>{{item.currency}} 이상이라면 세금이 포함되어 있음
        </b-form>
      </b-col>
    </b-row>

    <!--
      230628 blu.price.PRICE_TABLE_VER 1.1.0: margin 설정은 모두 use_yn: n 인 shop 에 들어있고, 대부분은 boutique, edit_shop 임을 확인, 비활성화 한다.
    -->
    <!--
    <div class="title-sm">마진 <small>마진은 카드수수료를 제외하고 계산됩니다</small></div>
    <b-row>
      <b-col>
        <small>일괄 마진율(비워두면 비활성화)</small>
        <b-form inline>
          <b-form-input class="w-60px" v-model.number="item.margin_rate"></b-form-input>%
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <small>마진 하한 조건식(비워두면 비활성화)</small> <i class="fa fa-question-circle ml-1" v-b-tooltip="'역마진일 때 마진을 올리려면 0% 이하를 선택하면 됩니다'"></i>
        <b-form inline>
          마진이 &nbsp;<b-input v-model.number="item.margin_if" class="w-60px"></b-input>% 이하라면 &nbsp;<b-input v-model.number="item.margin_if_then" class="w-60px"></b-input>% 로 조정
        </b-form>
      </b-col>
      <b-col lg="6">
        <small>마진 상한 조건식(비워두면 비활성화)</small>
        <b-form inline>
          마진이 &nbsp;<b-input v-model.number="item.margin_if_over" class="w-60px"></b-input>% 이상이라면 &nbsp;<b-input v-model.number="item.margin_if_over_then" class="w-60px"></b-input>% 로 조정
        </b-form>
      </b-col>
    </b-row>
    -->

    <!--<b-row>
      <b-col class="mb-2" cols="6" lg="3">
        <small>역마진(Sale&lt;Supply) 허용</small><br/>
        <b-form-radio-group class="col-form-label" v-model="item.margin_can_be_minus" :options="[
            {text: 'Y', value: 'y'},
            {text: 'N', value: 'n'}
          ]">
        </b-form-radio-group>
      </b-col>
      <b-col class="mb-2" cols="6" lg="3">
        <small>역마진일 때 적용할 마진율</small><br/>
        <b-form inline>
          <b-form-input class="w-50px" v-model.number="item.if_minus_margin_then"></b-form-input>%
        </b-form>
      </b-col>
    </b-row>-->
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: "ShopPriceBase",
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    priceMap: Object,
    options: Object,
    category: Array,
    categoryMap: Object,
  },
  data() {
    return {
      selectedCategory: null,
      rate: null,
      modal: {
        fee: false
      },

      // 위탁판매 Shop 일 경우 샘플
      consignArr: [],
      consignPrice: 0,
      consignAmountSum: 0,
    }
  },
  async created() {
    this.consignArr = this.$utils.clone(this.item.consignUsedSellerRate || []);
  },
  mounted() {
    this.calcRebateConsignSeller();
  },
  watch: {
    consignPrice() {
      this.calcRebateConsignSeller();
    }
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    dpPositiveRate: {
      get() {
        return Math.max((this.item.dpPositiveBaseRate || 0) + (this.item.dpPositiveCondRate || 0) + (this.item.dpPositiveBalaanRate || 0), this.item.dpMinRate || 0);
      },
    },
    dpNegativeRate: {
      get() {
        return (this.item.dpNegativeBaseRate || 0) + (this.item.dpNegativeCondRate || 0) + (this.item.dpNegativeBalaanRate || 0);
      },
    }
  },
  methods: {
    localDeliveryFocus(type, isSupply) {
      if (type === 'local') {
        if (isSupply) this.item.local_delivery_supply_cost_type = 'local';
        else this.item.local_delivery_cost_type = 'local';
      } else if (type === 'krw') {
        if (isSupply) this.item.local_delivery_supply_cost_type = 'krw';
        else this.item.local_delivery_cost_type = 'krw';
      } else {

      }
      this.$forceUpdate();
    },
    addCategoryFee() {
      if (!this.selectedCategory) return alert('카테고리를 선택해주세요');
      const category = this.selectedCategory.category
      if (typeof this.rate !== 'number' || this.rate < 0) return alert('수수료를 0 이상으로 설정해주세요');
      this.item.categoryFee = this.item.categoryFee || [];
      if (this.item.categoryFee.some(e => e.category === category)) return alert(`${category} 카테고리는 이미 존재합니다. 기존 설정을 삭제 후 추가해주세요`);
      this.item.categoryFee.push({category: category, rate: this.rate});
      this.selectedCategory = null;
      this.rate = null;
      this.modal.fee = false;
      this.$forceUpdate();
    },
    removeCategoryFee(feeObj) {
      this.item.categoryFee.splice(this.item.categoryFee.indexOf(feeObj), 1);
      this.$forceUpdate();
    },
    calcRebateConsignSeller() {
      let total = 0;
      this.consignArr = this.$utils.clone(this.item.consignUsedSellerRate || []);
      for (const rateObj of this.consignArr) {
        const {gt, lte, amount, rate} = rateObj;
        if (gt < this.consignPrice) {
          const rateAmount = Math.floor((Math.min(this.consignPrice, lte) - gt) * rate / 100);
          const amountSum = amount + rateAmount;
          Vue.set(rateObj, 'sampleAmount', amountSum);
          total += amountSum;
        } else {
          Vue.set(rateObj, 'sampleAmount', 0);
        }
      }
      this.consignAmountSum = total;
    }
  }
}
</script>

<style scoped>

</style>
